/* width */

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Track below and above */
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
