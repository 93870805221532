.fullscreen-modal .ant-modal,
.fullscreen-modal .ant-modal-content {
  height: 100vh;
  width: 100vw !important;
  margin: 0 !important;
  top: 0 !important;
  border-radius: 0;
}

.fullscreen-modal .ant-modal-body {
  height: calc(100vh - 110px);
  width: calc(100vw - 32px);
}
